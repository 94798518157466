import { API, apiRequest } from "../api";
import { GetUserInfosReq, GetUserInfosRsp, ResetPasswordReq, ResetPasswordRsp } from "./admin";
import {
    GetUserReq,
    GetUserRsp,
    LoginReq,
    LoginRsp,
    RegisterReq,
    RegisterRsp,
    ResumeLoginReq,
    ResumeLoginRsp,
} from "./user";
import {
    SendEmailCodeReq,
    SendEmailCodeRsp,
    SendOpCheckCodeReq,
    SendOpCheckCodeRsp,
    SendSMSCodeReq,
    SendSMSCodeRsp,
} from "./verification";

const apiGetUser: API<GetUserReq, GetUserRsp> = {
    url: "/api/user/get",
};

export function methodGetUser(dispatch: any, req: GetUserReq) {
    return apiRequest({ api: apiGetUser, req, dispatch });
}

const apiLogin: API<LoginReq, LoginRsp> = {
    url: "/api/user/login",
};

export function methodLogin(dispatch: any, req: LoginReq) {
    return apiRequest({ api: apiLogin, req, dispatch, backdrop: true });
}

const apiResumeLogin: API<ResumeLoginReq, ResumeLoginRsp> = {
    url: "/api/user/resume_login",
};

export function methodResumeLogin(dispatch: any, req: ResumeLoginReq) {
    return apiRequest({ api: apiResumeLogin, req, dispatch, backdrop: true, dealErr: true });
}

const apiRegister: API<RegisterReq, RegisterRsp> = {
    url: "/api/user/register",
};

export function methodRegister(dispatch: any, req: RegisterReq) {
    return apiRequest({ api: apiRegister, req, dispatch, backdrop: true });
}

const apiResetPassword: API<ResetPasswordReq, ResetPasswordRsp> = {
    url: "/api/user/reset_password",
};

export function methodResetPassword(dispatch: any, req: ResetPasswordReq) {
    return apiRequest({ api: apiResetPassword, req, dispatch, backdrop: true });
}

const apiGetUserInfos: API<GetUserInfosReq, GetUserInfosRsp> = {
    url: "/api/user/get_infos",
};

export function methodGetUserInfos(dispatch: any, req: GetUserInfosReq) {
    return apiRequest({ api: apiGetUserInfos, req, dispatch, backdrop: true });
}

const apiSendOpCheckCode: API<SendOpCheckCodeReq, SendOpCheckCodeRsp> = {
    url: "/api/user/send_op_check_code",
};

export function methodSendOpCheckCode(dispatch: any, req: SendOpCheckCodeReq) {
    return apiRequest({ api: apiSendOpCheckCode, req, dispatch, backdrop: true });
}

const apiSendEmailCode: API<SendEmailCodeReq, SendEmailCodeRsp> = {
    url: "/api/email/send_code",
};

export function methodSendEmailCode(dispatch: any, req: SendEmailCodeReq) {
    return apiRequest({ api: apiSendEmailCode, req, dispatch, backdrop: true });
}

const apiSendSMSCode: API<SendSMSCodeReq, SendSMSCodeRsp> = {
    url: "/api/sms/send_code",
};

export function methodSendSMSCode(dispatch: any, req: SendSMSCodeReq) {
    return apiRequest({ api: apiSendSMSCode, req, dispatch, backdrop: true });
}
