import { EnumItem, Make_GetEnumName } from "../Enum";
import { RateCount, RateScore } from "../statistic/Count";
import { ExamResult } from "./result/Result";
import { Section } from "./Section";

export class Exam {
    id: string = "";
    user_id: string = "";
    template_id: string = "";
    name: string = "";
    kind: number = 0;
    kind_str: string = "";
    status: number = 0;
    sections: Section[] = [new Section()];
    result: ExamResult = new ExamResult();
    create_option: CreateExamOption = new CreateExamOption();
    cursor: ExamCursor = new ExamCursor();
    create_time_str: string = "";
    is_auto_commit: boolean = false;
    isBackendData: boolean = false;
}

export enum ExamKind {
    Random = 0,
    Simulate = 1,
    Exercise = 2,
    PracticeCollect = 3, // 收藏夹练习
    PracticeWrong = 4, // 错题集重练
    PracticeNote = 5, // 笔记列表练习
    PracticeIssue = 6, // 作文单题练习
    High = 7,
    Tailor = 8,
    Word = 9,
    Higher = 10,
}

export class ExamCursor {
    section_index: number = 0;
    question_index: number = 0;
}

export enum ExamStatus {
    NotStart = 0,
    Start = 1,
    PartCompleted = 2,
    Finished = 3,
    Close = 4,
}

export class CreateExamOption {
    kind: number = 0;
    version: number = 0;
    include_done: boolean = false;
    with_issue: boolean = false;
    enable_count_up: boolean = false;
    pick_first: boolean = false;
    math_create_kind: number = 0;
}

export enum MathCreateKind {
    None = 0,
    Real = 1,
    Challenge = 2,
}

export enum CreateExamKind {
    FullExam = 0, // 全真模考
    OnlyVerbal = 1, // 只做V
    OnlyQuantitative = 2, // 只做Q
    OnlyIssue = 3, // 只做作文
}

export class ExamSummary {
    id: string = "";
    name: string = "";
    create_time_stamp: number = 0;
    create_time_str: string = "";
    total_score: number = 0;
    v_score: number = 0;
    v_adjust: number = 0;
    v_correct: RateCount = new RateCount();
    q_score: number = 0;
    q_adjust: number = 0;
    q_correct: RateCount = new RateCount();
    i_score: RateScore = new RateScore();
}

export class AvgScore {
    total: number = 0;
    v: number = 0;
    vrank: number = 0;
    q: number = 0;
    qrank: number = 0;
    i: number = 0;
    irank: number = 0;
}

export enum FilterScopeKind {
    All = 1,
    AllExcludeLow3 = 2,
    Last3 = 3,
    Last5 = 4,
    Last10 = 5,
    ThisMonth = 6,
    ThisWeek = 7,
}

export const FilterScopeKindItems = [
    new EnumItem(FilterScopeKind.All, "全部"),
    new EnumItem(FilterScopeKind.AllExcludeLow3, "全部，除去最低3次"),
    new EnumItem(FilterScopeKind.Last3, "最近3次"),
    new EnumItem(FilterScopeKind.Last5, "最近5次"),
    new EnumItem(FilterScopeKind.Last10, "最近10次"),
    new EnumItem(FilterScopeKind.ThisWeek, "本周"),
    new EnumItem(FilterScopeKind.ThisMonth, "本月"),
];

export const FilterScopeHomeItems = [
    new EnumItem(FilterScopeKind.All, "全部"),
    new EnumItem(FilterScopeKind.Last3, "最近3次"),
    new EnumItem(FilterScopeKind.Last10, "最近10次"),
    new EnumItem(FilterScopeKind.ThisWeek, "本周"),
];

export const FilterScopeKindName = Make_GetEnumName(FilterScopeKindItems);

export enum FilterScoreKind {
    All = 1,
    OnlyTotal = 2,
    OnlyV = 3,
    OnlyQ = 4,
}

export const FilterScoreKindItems = [
    new EnumItem(FilterScoreKind.All, "全部"),
    new EnumItem(FilterScoreKind.OnlyTotal, "只看总分"),
    new EnumItem(FilterScoreKind.OnlyV, "只看V"),
    new EnumItem(FilterScoreKind.OnlyQ, "只看Q"),
];
