import { Trade } from "../../model/security/Pay";
import { User } from "../../model/UserData";

export interface InitPayReq {
    user_id: string;
    kind: number;
    param: string;
    code: string;
}

export class InitPayRsp {
    price: number = 0;
    title: string = "";
    qrcode_url: string = "";
    qrcode_text: string = "";
    trade: Trade = new Trade();
}

export interface GetTradeReq {
    id: string;
}

export interface GetTradeRsp {
    trade: Trade;
    user: User;
}
