import { AnyAction, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CommentKind } from "../../model/post/comment";
import { Post } from "../../model/post/post";
import { Topic } from "../../model/post/topic";

export function isPostAction(action: AnyAction): action is AnyAction {
    return action.type.startsWith("post_runtime");
}

export const postRuntimeSlice = createSlice({
    name: "post_runtime",
    initialState: {
        opened: false,
        topic: new Topic(),
        postID: "",
        post: new Post(),
        postRootInReply: false,
        postRootCommentKind: CommentKind.Answer,
        addPostDialog: false,
    },
    reducers: {
        actionSetPostID: (state, action: PayloadAction<string>) => {
            state.postID = action.payload;
        },
        actionSetPost: (state, action: PayloadAction<Post>) => {
            state.post = action.payload;
        },
        actionSetPostOpened: (
            state,
            action: PayloadAction<{
                opened: boolean;
                rootInReply?: boolean;
                rootCommentKind?: CommentKind;
            }>
        ) => {
            state.opened = action.payload.opened;
            if (action.payload.rootInReply !== undefined) {
                state.postRootInReply = action.payload.rootInReply;
            }
            if (action.payload.rootCommentKind !== undefined) {
                state.postRootCommentKind = action.payload.rootCommentKind;
            }
        },
        actionSetTopic: (state, action: PayloadAction<Topic>) => {
            state.topic = action.payload;
        },
        actionSetAddPostDialog: (state, action: PayloadAction<boolean>) => {
            state.addPostDialog = action.payload;
        },
    },
});

export const { actionSetPost, actionSetPostID, actionSetPostOpened, actionSetTopic } =
    postRuntimeSlice.actions;

export default postRuntimeSlice.reducer;
