import { RootState } from "../ReduxStore";

export const selectDialogRuntime = (state: RootState) => state.dialogRuntime;

export const selectShowPermissionDialog = (state: RootState) =>
    selectDialogRuntime(state).showPermission;
export const selectVIPDialog = (state: RootState) => selectDialogRuntime(state).vip;
export const selectShowOutOfLimitDialog = (state: RootState) =>
    selectDialogRuntime(state).outOfLimit;
export const selectPayDialog = (state: RootState) => selectDialogRuntime(state).pay;
export const selectRightsDialog = (state: RootState) => selectDialogRuntime(state).rights;

export const selectCreateFlashDialog = (state: RootState) => selectDialogRuntime(state).createFlash;
export const selectPayFlashDialog = (state: RootState) => selectDialogRuntime(state).payFlash;

export const selectLoginHintDialog = (state: RootState) => selectDialogRuntime(state).loginHint;
export const selectUploadDialog = (state: RootState) => selectDialogRuntime(state).upload;
export const selectPickDialog = (state: RootState) => selectDialogRuntime(state).pick;

export const selectSimulateNoPermission = (state: RootState) =>
    selectDialogRuntime(state).simulateNoPermission;

export const selectSupport = (state: RootState) => selectDialogRuntime(state).support;
