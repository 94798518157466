import { API, apiRequest } from "../api";
import {
    CreateCodeReq,
    CreateCodeRsp,
    GetCodeReq,
    GetCodeRsp,
    MarkCodeInvalidReq,
    MarkCodeInvalidRsp,
} from "./code";
import {
    CheckCoinReq,
    CheckCoinRsp,
    ManualChangeFlashCoinReq,
    ManualChangeFlashCoinRsp,
} from "./coin";
import {
    BatchCheckNewmanReq,
    BatchCheckNewmanRsp,
    BatchNewmanGiftReq,
    BatchNewmanGiftRsp,
} from "./newman";
import {
    BuyHigherReq,
    BuyHigherRsp,
    BuyHighReq,
    BuyHighRsp,
    BuyTailorCommonReq,
    BuyTailorCommonRsp,
    BuyTailorCustomReq,
    BuyTailorCustomRsp,
    FreezeReq,
    FreezeRsp,
    GetReportReq,
    GetReportRsp,
} from "./permission";
import { ManualGrantVIPReq, ManualGrantVIPRsp, RemoveVIPReq, RemoveVIPRsp } from "./vip";
import {
    AddVoucherReq,
    AddVoucherRsp,
    DeleteVoucherReq,
    DeleteVoucherRsp,
    GetVouchersReq,
    GetVouchersRsp,
    UpdateVoucherReq,
    UpdateVoucherRsp,
} from "./voucher";

const apiGetVouchers: API<GetVouchersReq, GetVouchersRsp> = {
    url: "/api/permission/get_vouchers",
};

export function methodGetVouchers(dispatch: any, req: GetVouchersReq) {
    return apiRequest({ api: apiGetVouchers, req, dispatch });
}

const apiUpdateVoucher: API<UpdateVoucherReq, UpdateVoucherRsp> = {
    url: "/api/permission/update_voucher",
};

export function methodUpdateVoucher(dispatch: any, req: UpdateVoucherReq) {
    return apiRequest({ api: apiUpdateVoucher, req, dispatch });
}

const apiAddVoucher: API<AddVoucherReq, AddVoucherRsp> = {
    url: "/api/permission/add_voucher",
};

export function methodAddVoucher(dispatch: any, req: AddVoucherReq) {
    return apiRequest({ api: apiAddVoucher, req, dispatch });
}

const apiDeleteVoucher: API<DeleteVoucherReq, DeleteVoucherRsp> = {
    url: "/api/permission/delete_voucher",
};

export function methodDeleteVoucher(dispatch: any, req: DeleteVoucherReq) {
    return apiRequest({ api: apiDeleteVoucher, req, dispatch });
}

const apiBuyHigh: API<BuyHighReq, BuyHighRsp> = {
    url: "/api/permission/set_high",
};

export function methodBuyHigh(dispatch: any, req: BuyHighReq) {
    return apiRequest({ api: apiBuyHigh, req, dispatch });
}

const apiBuyHigher: API<BuyHigherReq, BuyHigherRsp> = {
    url: "/api/permission/set_higher",
};

export function methodBuyHigher(dispatch: any, req: BuyHigherReq) {
    return apiRequest({ api: apiBuyHigher, req, dispatch });
}

const apiBuyTailorCustom: API<BuyTailorCustomReq, BuyTailorCustomRsp> = {
    url: "/api/permission/assign_custom_tailor_use",
};

export function methodBuyTailorCustom(dispatch: any, req: BuyTailorCustomReq) {
    return apiRequest({ api: apiBuyTailorCustom, req, dispatch });
}

const apiBuyTailorCommon: API<BuyTailorCommonReq, BuyTailorCommonRsp> = {
    url: "/api/permission/assign_common_tailor_use",
};

export function methodBuyTailorCommon(dispatch: any, req: BuyTailorCommonReq) {
    return apiRequest({ api: apiBuyTailorCommon, req, dispatch });
}

const apiGetReport: API<GetReportReq, GetReportRsp> = {
    url: "/api/permission/get_report",
};

export function methodGetReport(dispatch: any, req: GetReportReq) {
    return apiRequest({ api: apiGetReport, req, dispatch });
}

const apiManualGrantVIP: API<ManualGrantVIPReq, ManualGrantVIPRsp> = {
    url: "/api/permission/manual_grant_vip",
};

export function methodManualGrantVIP(dispatch: any, req: ManualGrantVIPReq) {
    return apiRequest({ api: apiManualGrantVIP, req, dispatch });
}

const apiRemoveVIP: API<RemoveVIPReq, RemoveVIPRsp> = {
    url: "/api/permission/remove_vip",
};

export function methodRemoveVIP(dispatch: any, req: RemoveVIPReq) {
    return apiRequest({ api: apiRemoveVIP, req, dispatch });
}

const apiFreeze: API<FreezeReq, FreezeRsp> = {
    url: "/api/user/freeze",
};

export function methodFreeze(dispatch: any, req: FreezeReq) {
    return apiRequest({ api: apiFreeze, req, dispatch });
}

const apiManualChangeFlashCoin: API<ManualChangeFlashCoinReq, ManualChangeFlashCoinRsp> = {
    url: "/api/permission/manual_change_flash_coin",
};

export function methodManualChangeFlashCoin(dispatch: any, req: ManualChangeFlashCoinReq) {
    return apiRequest({ api: apiManualChangeFlashCoin, req, dispatch });
}

const apiCheckCoin: API<CheckCoinReq, CheckCoinRsp> = {
    url: "/api/permission/check_coin",
};

export function methodCheckCoin(dispatch: any, req: CheckCoinReq) {
    return apiRequest({ api: apiCheckCoin, req, dispatch });
}

const apiBatchCheckNewman: API<BatchCheckNewmanReq, BatchCheckNewmanRsp> = {
    url: "/api/permission/batch_check_newman",
};

export function methodBatchCheckNewman(dispatch: any, req: BatchCheckNewmanReq) {
    return apiRequest({ api: apiBatchCheckNewman, req, dispatch });
}

const apiBatchNewmanGift: API<BatchNewmanGiftReq, BatchNewmanGiftRsp> = {
    url: "/api/permission/batch_newman_gift",
};

export function methodBatchNewmanGift(dispatch: any, req: BatchNewmanGiftReq) {
    return apiRequest({ api: apiBatchNewmanGift, req, dispatch, backdrop: true });
}

const apiCreateCode: API<CreateCodeReq, CreateCodeRsp> = {
    url: "/api/code/create",
};

export function methodCreateCode(dispatch: any, req: CreateCodeReq) {
    return apiRequest({ api: apiCreateCode, req, dispatch, backdrop: true });
}

const apiGetCode: API<GetCodeReq, GetCodeRsp> = {
    url: "/api/code/get",
};

export function methodGetCode(dispatch: any, req: GetCodeReq) {
    return apiRequest({ api: apiGetCode, req, dispatch, backdrop: true });
}

const apiMarkCodeInvalid: API<MarkCodeInvalidReq, MarkCodeInvalidRsp> = {
    url: "/api/code/mark_invalid",
};

export function methodMarkCodeInvalid(dispatch: any, req: MarkCodeInvalidReq) {
    return apiRequest({ api: apiMarkCodeInvalid, req, dispatch, backdrop: true });
}
