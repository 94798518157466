import { AnyAction, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Book } from "../../model/book/Book";

export function isBookAction(action: AnyAction): action is AnyAction {
    return action.type.startsWith("book_runtime");
}

export const bookRuntimeSlice = createSlice({
    name: "book_runtime",
    initialState: {
        bookID: "",
        book: new Book(),
    },
    reducers: {
        actionSetBookIDOrigin: (state, action: PayloadAction<string>) => {
            state.bookID = action.payload;
        },
        actionSetBook: (state, action: PayloadAction<Book>) => {
            state.book = action.payload;
            state.book.isBackend = true;
            state.bookID = action.payload.template.id;
        },
    },
});

export const { actionSetBook, actionSetBookIDOrigin } = bookRuntimeSlice.actions;

export default bookRuntimeSlice.reducer;
