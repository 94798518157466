import { Link as LinkBase, LinkProps as LinkBaseProps, Typography } from "@mui/material";
import { useCallback } from "react";
import { Outlet, useLocation } from "react-router";
import { acitonSetSupport, actionSetRightsDialog } from "../../../store/dialog/reducer";
import { useAppDispatch } from "../../../store/hooks";
import { Colors } from "../../../tools/color";
import { OpenURL } from "../../tools/url";

const color = Colors["gray"];

interface LinkProps extends LinkBaseProps {
    divider?: boolean;
    url?: string;
    onClick?: () => void;
}
function Link(props: LinkProps) {
    const { children, divider, url, onClick, ...pp } = props;
    return (
        <>
            {divider && <>&nbsp;|&nbsp;</>}
            <LinkBase
                color={color}
                underline="hover"
                {...pp}
                onClick={() => {
                    if (url !== undefined) {
                        OpenURL(url);
                    }
                    if (onClick !== undefined) {
                        onClick();
                    }
                }}
            >
                {children}
            </LinkBase>
        </>
    );
}

export function Footer() {
    const dispatch = useAppDispatch();
    return (
        <footer
            style={{
                textAlign: "center",
                padding: "20px 0",
                backgroundColor: "#f1f1f1",
            }}
        >
            <Typography sx={{ color: color, fontSize: 13 }}>
                <Link url="/agreement">用户须知</Link>
                <Link
                    divider
                    onClick={() => {
                        dispatch(acitonSetSupport(true));
                    }}
                >
                    联系我们
                </Link>
                <Link
                    divider
                    onClick={() => {
                        dispatch(actionSetRightsDialog(true));
                    }}
                >
                    会员权益表
                </Link>
            </Typography>
        </footer>
    );
}

export function FooterLayout() {
    const location = useLocation();

    const disableFooter = useCallback(() => {
        const p = location.pathname;
        return p === "/agreement" || p === "/login";
    }, [location]);
    return (
        <div>
            <Outlet />
            {!disableFooter() && <Footer />}
        </div>
    );
}
