import { LoginHintDialog } from "./component/dialog/LoginHintDialog";
import { OutOfLimitDialog } from "./component/dialog/OutOfLimitDialog";
import { VIPPayDialog } from "./component/dialog/PayDialog";
import PermissionMsgDialog from "./component/dialog/PermissionDialog";
import { RightsDialog } from "./component/dialog/RightsDialog";
import { SimulateNoPermissionDialog } from "./component/dialog/SimulateNoPermissionDialog";
import { SupportDialog } from "./component/dialog/SupportDialog";
import { VIPDialog } from "./component/dialog/VIPDialog";
import { UploadDialog } from "./component/question/detail/ImageUpload";
import { BuyCoinDialog } from "./page/flash/component/entrance/BuyCoinDialog";

export function Dialogs() {
    return (
        <>
            <PermissionMsgDialog />
            <VIPDialog />
            <OutOfLimitDialog />
            <VIPPayDialog />
            <RightsDialog />
            <LoginHintDialog />
            <BuyCoinDialog />
            <UploadDialog />
            <SimulateNoPermissionDialog />
            <SupportDialog />
        </>
    );
}
