import { Answer } from "../question/data/QuestionData";
import { RateCount } from "../statistic/Count";
import { QuestionCollect } from "./collect";
import { Review } from "./review";

export class Craft {
    user_id: string = "";
    id: string = "";
    wrong: QuestionWrong = new QuestionWrong();
    issue: QuestionIssue = new QuestionIssue();
    collect: QuestionCollect = new QuestionCollect();
    note: Note = new Note();
    in_higher: boolean = false;
    card: Card = new Card();

    isBackendData: boolean = false;
}

export class Note {
    text_input: TextInput = new TextInput();
    review: Review = new Review();
    reasons: OldNoteReason[] = [new OldNoteReason()];
    source: Source = new Source();
}

export class TextInput {
    review: string = "";
    difficulty: string = "";
    new_way: string = "";
    puzzle: string = "";
    note: string = "";
}

export class OldNoteReason {
    name: string = "";
    type: number = 0;
    checked: boolean = false;
}

export class Source {
    name: string = "";
    exam_id: string = "";
    template_id: string = "";
    update_time_str: string = "";
    update_time_stamp: number = 0;
}

export enum UpdateCraftKind {
    Collect = 1,
    Note = 3,
    Mastery = 4,
    Reasons = 5,
    Improvement = 6,
    Card = 7,
    Issue = 8,
    Review = 9,
    RemoveWrong = 10, // 从错题集中去除
}

export class Card {
    finished: boolean = false;
    finish_time_stamp: number = 0;
    finish_time_str: string = "";
    collected: boolean = false;
    collect_time_stamp: number = 0;
    collect_time_str: string = "";
}

export class QuestionWrong {
    answer: Answer = new Answer();
    wrong_time_str: string = "";
    source: Source = new Source();
    count: RateCount = new RateCount();
}

export class QuestionIssue {
    answer: string = "";
    result: IssueResult = new IssueResult();
}

export class IssueResult {
    status: number = 0;
    score: number = 0;
    score_str: string = "";
    rank: number = 0;
    comment: string = "";
    progress: number | null = null;
    start_time_stamp: number = 0;
}

export enum IssueResultStatus {
    Empty = 0,
    Judging = 1,
    Finished = 2,
    Failed = 3,
}

export class CraftBank {
    is_backend_data: boolean = false;
    craftMap: Map<string, Craft> = new Map(); // key:id
}
