import { Box, BoxProps } from "@mui/material";

// x轴排列的Stack, 默认居中
export function XStack(props: BoxProps) {
    const { sx, ...pp } = props;
    return (
        <Box
            sx={{ display: "flex", flexDirection: "row", alignItems: "center", ...sx }}
            {...pp}
        ></Box>
    );
}

interface YStackProps extends BoxProps {
    noCenter?: boolean;
}
// y轴排列的Stack, 默认居中
export function YStack(props: YStackProps) {
    const { noCenter, sx, ...pp } = props;
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: noCenter ? undefined : "center",
                ...sx,
            }}
            {...pp}
        ></Box>
    );
}
