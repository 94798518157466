import { CssBaseline } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider } from "@mui/material/styles";
import { decodeJwt } from "jose";
import { SnackbarProvider } from "notistack";
import { useEffect, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { methodResumeLogin } from "./api/apiuser/api";
import { methodGetWords } from "./api/apiword/api";
import { GetTags } from "./api/Tag";
import { NoCopy } from "./bblib/component/copy";
import { AlertError } from "./bblib/component/Snackbar";
import GetGlobalConfig from "./config/GlobalConfig";
import { Dialogs } from "./dialogs";
import "./index.css";
import { BackendTagBank } from "./model/TagData";
import { AuthData, TOKEN_KEY } from "./model/TokenAuthData";
import { bbRouter } from "./router";
import store from "./store/ReduxStore";
import { actionSetTagBank, actionSetWordBank } from "./store/root/reducer";
import {
    selectBackdrop,
    selectBackdropCount,
    selectTagBank,
    selectWordBank,
} from "./store/root/selector";
import { actionUpdateUserAuth, actionUserLogin } from "./store/user/reducer";
import { selectAdminMode, selectIsOperator, selectLogin } from "./store/user/selector";
import { bbTheme } from "./tools/theme";

const LoadToken = (dispatch: any): [string, AuthData] => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
        return ["", { user_id: "" } as AuthData];
    }
    const data = decodeJwt(token);
    const auth = data as unknown as AuthData;
    dispatch(actionUpdateUserAuth(auth));
    return [token, auth];
};

const App = () => {
    const dispatch = useDispatch();

    const login = useSelector(selectLogin);
    const tagBank = useSelector(selectTagBank);
    const wordBank = useSelector(selectWordBank);

    const [loginChecked, setLoginChecked] = useState(false);
    const adminMode = useSelector(selectAdminMode);
    const isOp = useSelector(selectIsOperator);

    // init
    useEffect(() => {
        console.log("api version", GetGlobalConfig().api_version);

        // 在组件加载时，添加版本号作为查询参数
        const script = document.createElement("script");
        const version =
            process.env.NODE_ENV === "production"
                ? GetGlobalConfig().api_version
                : Math.random().toString(36).slice(2);
        script.src = `/script.js?v=${version}`;
        document.body.appendChild(script);

        // load token
        const [token, auth] = LoadToken(dispatch);
        // resume login from token
        if (token.length > 0 && auth.user_id.length > 0) {
            dealLogin(dispatch).then(() => {
                setLoginChecked(true);
            });
        } else {
            setLoginChecked(true);
        }

        return () => {
            // 在组件卸载时，移除添加的脚本
            document.body.removeChild(script);
        };
    }, []);

    // auto fetch tagBank
    useEffect(() => {
        if (!login) {
            return;
        }
        if (tagBank.is_backend_data) {
            return;
        }
        GetTags({
            afterSuccess: (b: BackendTagBank) => {
                dispatch(actionSetTagBank(b));
            },
            dispatch,
        });
    }, [tagBank.is_backend_data, login, dispatch]);

    // auto fetch wordBank
    useEffect(() => {
        if (!login) {
            return;
        }
        if (!adminMode) {
            return;
        }
        if (wordBank.is_backend_data) {
            return;
        }
        methodGetWords(dispatch, {}).then((rsp) => {
            dispatch(actionSetWordBank(rsp.word_infos));
        });
    }, [wordBank.is_backend_data, login, dispatch, adminMode]);

    return (
        <NoCopy disabled={adminMode || isOp}>
            <Dialogs />
            <AppBackdrop />
            {loginChecked && <RouterProvider router={bbRouter} />}
            {/* <Footer /> */}
        </NoCopy>
    );
};

function AppBackdrop() {
    const backdrop = useSelector(selectBackdrop);
    const backdropCount = useSelector(selectBackdropCount);
    return (
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={backdrop || backdropCount > 0}
            onClick={() => {}}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

function dealLogin(dispatch: any): Promise<void> {
    const cfg = GetGlobalConfig();
    if (!cfg.auto_resume_login) {
        return new Promise(() => {});
    }
    return methodResumeLogin(dispatch, {})
        .then((rsp) => {
            dispatch(actionUserLogin(rsp.user));
        })
        .catch((err) => {
            AlertError("恢复登录失败，请重新登录");
        });
}

const WebApp = () => (
    <Provider store={store}>
        <ThemeProvider theme={bbTheme}>
            <SnackbarProvider maxSnack={5}>
                <CssBaseline />
                <App />
            </SnackbarProvider>
        </ThemeProvider>
    </Provider>
);

export default WebApp;
