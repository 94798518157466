import { useSelector } from "react-redux";
import { BBDialog } from "../../bblib/component/dialog/BBDialog";
import { actionSetSimulateNoPermisson } from "../../store/dialog/reducer";
import { selectSimulateNoPermission } from "../../store/dialog/selector";
import { useAppDispatch } from "../../store/hooks";

export function SimulateNoPermissionDialog() {
    const dispatch = useAppDispatch();
    const open = useSelector(selectSimulateNoPermission);
    const setOpen = (b: boolean) => {
        dispatch(actionSetSimulateNoPermisson(b));
    };
    return (
        <BBDialog open={open} setOpen={setOpen} content={<>普通会员只能访问公开题目</>} noCancel />
    );
}
