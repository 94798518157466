import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
} from "@mui/material";
import { CSSProperties, useState } from "react";

export interface BBDialogBaseProps {
    content: any;
    title?: any;
    titleColor?: string;
    onClose?: () => void;
    onConfirm?: () => void;
    confirmTitle?: string;
    size?: DialogProps["maxWidth"];
    disableConfirm?: boolean; // 确认按钮变灰色
    noCancel?: boolean; // 没有取消按钮
    backgroundColor?: CSSProperties["backgroundColor"];
}

export interface BBDialogProps extends BBDialogBaseProps {
    open: boolean;
    setOpen: (b: boolean) => void;
}

export function useBBDialog(props: BBDialogBaseProps): [any, (b: boolean) => void] {
    const { ...pp } = props;
    const [open, setOpen] = useState(false);
    return [<BBDialog open={open} setOpen={setOpen} {...pp} />, setOpen];
}

export function BBDialog(props: BBDialogProps) {
    const {
        open,
        setOpen,
        content,
        onConfirm: onConfirmRef,
        onClose: onCloseRef,
        title,
        titleColor,
        size,
        disableConfirm,
        noCancel,
        confirmTitle,
        backgroundColor,
    } = props;

    const handleConfirm = () => {
        if (onConfirmRef !== undefined) {
            onConfirmRef();
        }
        setOpen(false);
    };

    const handleClose = () => {
        if (onCloseRef !== undefined) {
            onCloseRef();
        }
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth={size !== undefined ? size : "sm"}
        >
            {title && <DialogTitle color={titleColor}>{title}</DialogTitle>}
            <DialogContent sx={{ backgroundColor: backgroundColor }}>{content}</DialogContent>
            <DialogActions sx={{ backgroundColor: backgroundColor }}>
                <Button onClick={handleConfirm} disabled={disableConfirm}>
                    {confirmTitle ? confirmTitle : "确认"}
                </Button>
                {!noCancel && (
                    <Button onClick={handleClose} autoFocus color="info">
                        取消
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}
