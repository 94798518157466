import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { QuestionCategory } from "../../model/question/data/category";
import { selectQuestionCategory } from "../../store/question/selector";

export function NoCopy(props: PropsWithChildren<{ disabled: boolean }>) {
    const { disabled: disabledRef, children } = props;

    const cat = useSelector(selectQuestionCategory);

    const isIssue = cat === QuestionCategory.Issue;

    const disabled = isIssue ? true : disabledRef;

    return (
        <>
            {disabled ? (
                <>{children}</>
            ) : (
                <div
                    onCopy={(e) => {
                        e.preventDefault();
                    }}
                    style={{
                        userSelect: "none",
                        WebkitUserSelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                    }}
                >
                    {children}
                </div>
            )}
        </>
    );
}

export function AllowCopy(props: PropsWithChildren<{}>) {
    const { children } = props;
    return (
        <div
            onCopy={undefined}
            style={{
                userSelect: undefined,
                WebkitUserSelect: undefined,
                MozUserSelect: undefined,
                msUserSelect: undefined,
            }}
        >
            {children}
        </div>
    );
}
