import { ReasonInput } from "./review";

export class ReasonLib {
    question_categories: QuestionCategoryReasons[] = [];
    isBackendData: boolean = false;
}

export class BackendReasonLib {
    question_categories: QuestionCategoryReasons[] = [];
}

export class QuestionCategoryReasons {
    question_category: number = 0;
    kinds: KindReasons[] = [];
}

export class KindReasons {
    kind: number = 0;
    kind_desp: string = "";
    subs: Reason[] = [];
}

export class Reason {
    id: string = "";
    allow_custom: boolean = false;
    reason_desp: string = "";
    improvement_hint: string = "";
    hide: boolean = false;
}

export class ReasonWithInput {
    reason: Reason = new Reason();
    input: ReasonInput = new ReasonInput();
}
export class KindReasonInputs {
    kind: number = 0;
    kind_desp: string = "";
    reasons: ReasonWithInput[] = [];
}
