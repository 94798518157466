export class Review {
    mastery: number = 0;
    reasons: ReasonInput[] = [];
    improvement: Improvement = new Improvement();
    reviewed: boolean = false;
}

export class Improvement {
    text: string = "";
}

export class ReasonInput {
    reason_id: string = "";
    input_text: string = "";
}
