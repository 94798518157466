import { EnumItem, Make_GetEnumItem, Make_GetEnumName } from "../../Enum";
import { Tag } from "../../TagData";
import { WordConnect } from "../../Word";
import { RichText, Translate } from "../RichText";
import { CardLearn } from "./CardLearn";

export class QuestionData {
    id: string = "";
    category: number = 0;
    category_str: string = "";
    kind: number = 0;
    kind_str: string = "";
    kind_short_name: string = "";
    difficulty: number = 0;
    group_id: number = 0;
    description: string = "";
    passage: PassageData = new PassageData();
    inquiry: InquiryData = new InquiryData();
    input_method: InputMethod = new InputMethod();
    standard_answer: StandardAnswer = new StandardAnswer();
    analysis: Analysis = new Analysis();
    tag_ids: string[] = [];
    visibility: number = 0;
    visibility_str: string = "";
    brief: string = "";
    accessable: boolean = false;
    is_backend: boolean = false;
    manual_mark: ManualMark = new ManualMark();
    source_names: string[] = [];
    card_learn: CardLearn = new CardLearn();
    support_translate: boolean = false;
}

export class ManualMark {
    xi: boolean = false;
    doggie: boolean = false;
    chick: boolean = false;
    card: boolean = false;
    pick: boolean = false;
    support_card: boolean = false;
}

export enum QuestionDifficulty {
    None = 0,
    L1 = 1,
    L2 = 4,
    L3 = 2,
    L4 = 5,
    L5 = 3,
}

export const QuestionDifficultyItems = [
    new EnumItem(QuestionDifficulty.L1, "1"),
    new EnumItem(QuestionDifficulty.L2, "2"),
    new EnumItem(QuestionDifficulty.L3, "3"),
    new EnumItem(QuestionDifficulty.L4, "4"),
    new EnumItem(QuestionDifficulty.L5, "5"),
] as EnumItem[];

export const QuestionDifficultyName = Make_GetEnumName(QuestionDifficultyItems);

export enum QuestionVisibility {
    Hide = 0,
    Public = 1,
    Lock = 2,
    Admin = 3,
}

export const QuestionVisibilityItems = [
    new EnumItem(QuestionVisibility.Hide, "3类题（隐藏）"),
    new EnumItem(QuestionVisibility.Public, "1类题（公开）"),
    new EnumItem(QuestionVisibility.Lock, "2类题（锁定）"),
    new EnumItem(QuestionVisibility.Admin, "4类题（地下）"),
];

export const QuestionVisibilityName = Make_GetEnumName(QuestionVisibilityItems);

export class Answer {
    selects: number[] = [];
    multi_selects: boolean[] = [];
    text: string = "";
    description: string = "";
    text2: string = "";
}

export class StandardAnswer {
    answer: Answer = new Answer();
    accessable: boolean = false;
}

export class Analysis {
    text: RichText = new RichText();
    accessable: boolean = false;
    key_words: string[] = [];
    synonyms: string[] = [];
    antonyms: string[] = [];
    show: boolean = false;
}

export class OptionData {
    text: RichText = new RichText();
    translate: Translate = new Translate();
    word_connect: WordConnect = new WordConnect();
}

export enum PassageType {
    Simple = 0, // 普通选择题题干
    Reading = 1, // 阅读题题干
    Compare = 2, // 比较题题干
    Issue = 3, // 作文题干
}

export const PassageTypeItems = [
    new EnumItem(PassageType.Simple, "普通上下布局"),
    new EnumItem(PassageType.Reading, " 阅读左右布局"),
    new EnumItem(PassageType.Compare, "数学比较题布局"),
    new EnumItem(PassageType.Issue, "作文布局"),
];

export const PassageTypeName = Make_GetEnumName(PassageTypeItems);

export class PassageData {
    type: number = 0;
    text: RichText = new RichText();
    translate: Translate = new Translate();
    quantity: Quantity = new Quantity();
    word_connect: WordConnect = new WordConnect();
}

export class Quantity {
    a: QuantityItem = new QuantityItem();
    b: QuantityItem = new QuantityItem();
}

export class QuantityItem {
    text: RichText = new RichText();
    translate: Translate = new Translate();
    word_connect: WordConnect = new WordConnect();
}

export class InquiryData {
    text: RichText = new RichText();
    translate: Translate = new Translate();
    word_connect: WordConnect = new WordConnect();
}

export enum InputKind {
    SingleOption = 0, // 单空
    SingleBox = 1, // 单空,方块样式
    DoubleBox = 2, // 双空,方块样式
    TripleBox = 3, // 三空,方块样式
    MultiBlock = 4, // 多选
    Fill = 5, // 填空
    SixTwoBlock = 6, // 六选二
    SelectSentence = 7, // 选句子
    Fraction = 8, // 分数
    Issue = 10, // 作文
    None = 11,
    Word = 12, // 单词
}

export const InputTypeItems = [
    new EnumItem(InputKind.SingleBox, "单空(填空方型)"),
    new EnumItem(InputKind.DoubleBox, "双空(填空方型)"),
    new EnumItem(InputKind.TripleBox, "三空(填空方型)"),
    new EnumItem(InputKind.SixTwoBlock, "六选二"),
    new EnumItem(InputKind.SelectSentence, "选句子"),
    new EnumItem(InputKind.SingleOption, "单选(小圆圈)"),
    new EnumItem(InputKind.MultiBlock, "多选"),
    new EnumItem(InputKind.Fill, "文本输入"),
    new EnumItem(InputKind.Fraction, "文本输入-分数"),
    new EnumItem(InputKind.Issue, "作文"),
    new EnumItem(InputKind.Word, "单词"),
];

export const InputTypeName = Make_GetEnumName(InputTypeItems);

export class InputMethod {
    kind: number = 0;
    option_groups: OptionGroup[] = [new OptionGroup()];
    fillable: boolean = false;
    fill_prefix: string = "";
    fill_suffix: string = "";
    group_size: number = 0;
    option_size: number = 0;
}

export class OptionGroup {
    options: OptionData[] = [new OptionData()];
}

export class GetQuestionRsp {
    question_data: QuestionData = new QuestionData();
}

export class UpdateQuestionDataRsp {
    data: QuestionData = new QuestionData();
}

export class UpdateQuestionTagsRsp {
    tags: Tag[] = [];
    data: QuestionData = new QuestionData();
}

export class ReadQuestionJsonRsp {
    content: string = "";
}

export class CreateQuestionRsp {
    id: string = "";
}

export class GetCreateQuestionHintRsp {
    hint: string = "";
}

export class QuestionEditExtraData {
    mark_xi: boolean = false;
    mark_doggie: boolean = false;
    std_ans_text: string = "";
}

export enum MarkKind {
    Xi = 1,
    Doogie = 2,
    Chick = 3,
    Pick = 4,
    Card = 5,
    Best = 6,
}

export const MarkKindItems = [
    new EnumItem(MarkKind.Xi, "西"),
    new EnumItem(MarkKind.Doogie, "狗"),
    new EnumItem(MarkKind.Chick, "鸡"),
    new EnumItem(MarkKind.Pick, "精选"),
    new EnumItem(MarkKind.Best, "超级精选"),
    new EnumItem(MarkKind.Card, "闪卡"),
];

export const MarkKindName = Make_GetEnumName(MarkKindItems);
export const MarkKindItem = Make_GetEnumItem(MarkKindItems);
