import { BookParam } from "./BookTemplate";

export class Book {
    id: string = "";
    user_id: string = "";
    user_name: string = "";
    template: BookTemplate = new BookTemplate();
    exam_id: string = "";
    title: string = "";
    sub_title: string = "";
    status: number = 0;
    limit: number = 0;
    expire_hint: string = "";
    isBackend: boolean = false;
}

export enum BookLimit {
    Hide = 0, // 隐藏: 不可见
    Forbid = 1, // 禁止: 可见, 无权限, 点击展示二维码
    Lock = 2, // 锁定: 可见, 有/无权限, 背景灰色, 不可点击按钮（开启考试）
    Prevent = 3, // 限制: 可见, 有/无权限, 背景彩色, 不可点击按钮
    Free = 4, // 开放: 可见, 有权限, 解锁, 背景彩色, 可正常操作
}

export enum BookStatus {
    NotOpen = 0,
    Start = 1,
    Finish = 2,
    Expired = 3, // 未完成, 但是超过5天过期
}

export enum BookKind {
    None = 0,
    Tailor = 4,
    High = 5,
    Practice = 6,
    Higher = 8,
    Report = 9,
    ArchivedExercise = 10,
    Exercise = 11,
    ByType = 12,
    Hard = 13,
    Simulate = 14,
    Flash = 15,
    Word = 16,
}

export enum BookPractice {
    None = 0,
    Collect = 1,
    Wrongs = 2,
    Note = 3,
    Issue = 4,
}

export class BookTemplate {
    id: string = "";
    index: number = 0;
    title: string = "";
    sub_title: string = "";
    param: BookParam = new BookParam();
    kind: number = 0;
    kind2: number = 0;
    kind3: number = 0;
    kind4: number = 0;
    unlock_date_stamp: number = 0;

    is_tailor_common: boolean = false;
    is_tailor_custom: boolean = false;
    label: string = "";
    hide: boolean = false;
}

export enum BookHardKind {
    None = 0,
    Math = 1,
    Fill = 2,
}

export enum BookTailorCustomKind {
    MixQV = 1,
    OnlyV = 2,
    Pro = 3,
}

export enum BookWordKind {
    None = 0,
    Word = 1,
    SixTwo = 2,
    Math = 3,
}

export enum BookExerciseKind {
    None = 0,
    Public = 1,
    Lock = 2,
    OldPick = 3,
    Pick = 4,
    Best = 5,
}

export enum BookByTypeKind {
    None = 0,
    Math = 1,
    Reading = 2,
}

export class WordQuestionInfo {
    kind: number = 0;
    id: string = "";
    option_ids: string[] = [];
}
