import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { BBDialog } from "../../bblib/component/dialog/BBDialog";
import { acitonSetSupport } from "../../store/dialog/reducer";
import { selectSupport } from "../../store/dialog/selector";
import { useAppDispatch } from "../../store/hooks";

export function SupportDialog() {
    const dispatch = useAppDispatch();
    const open = useSelector(selectSupport);
    const setOpen = (b: boolean) => {
        dispatch(acitonSetSupport(b));
    };
    return (
        <BBDialog
            open={open}
            setOpen={setOpen}
            title="客服微信"
            content={
                <Box sx={{ minWidth: "200px", minHeight: "100px" }}>
                    <p>
                        <b>微信1：BBGRE01</b>
                    </p>
                    <p>
                        <b>微信2：BBGRE02</b>
                    </p>
                    <br />
                    <p>客服工作时间: 北京时间早9-晚9</p>
                    <p>预计响应时间:2个工作日内</p>
                </Box>
            }
            noCancel
        />
    );
}
