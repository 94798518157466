import { createSelector } from "reselect";
import { Section, SectionKind } from "../../model/exam/Section";
import { IssueResult } from "../../model/user/craft";
import { RootState } from "../ReduxStore";

export const selectExamRuntime = (state: RootState) => state.examRuntime;
export const selectExamStage = (state: RootState) => selectExamRuntime(state).stage;
export const selectExamShowResult = (state: RootState) => selectExamRuntime(state).showResult;

export const selectExam = (state: RootState) => selectExamRuntime(state).exam;
export const selectExamID = (state: RootState) => selectExamRuntime(state).exam.id;
export const selectExamIDOrigin = (state: RootState) => selectExamRuntime(state).examID;
export const selectExamStatus = (state: RootState) => selectExam(state).status;
export const selectExamResult = (state: RootState) => selectExam(state).result;
export const selectExamInterpret = (state: RootState) => selectExamResult(state).interpret;

export const selectSections = (state: RootState) => selectExam(state).sections;
export const selectSectionIndex = (state: RootState) => selectExamRuntime(state).sectionIndex;
export const selectSection = createSelector(
    [selectExam, selectSectionIndex],
    (exam, sectionIndex) => {
        return sectionIndex !== -1 ? exam.sections[sectionIndex] : new Section();
    }
);

export const selectSectionsNoIssue = createSelector([selectSections], (sections: Section[]) =>
    sections.filter((sec) => sec.kind !== SectionKind.Issue)
);
// export const selectSectionsNoIssue =  (state: RootState) =>
//     selectSections(state).filter((sec) => sec.kind !== SectionKind.Issue);
export const selectIssueResult = createSelector([selectExam], (exam): IssueResult | undefined => {
    if (exam.sections.length === 0) {
        return;
    }
    if (exam.sections.length === 0) {
        return;
    }
    let section = exam.sections[0];
    if (section.kind !== SectionKind.Issue) {
        return;
    }
    if (section.questions.length === 0) {
        return;
    }
    let question = section.questions[0];
    return question.result.issue;
});

export const selectSectionAction = (state: RootState) => selectSection(state).action;

export const selectPageIndex = (state: RootState) => selectExamRuntime(state).pageIndex;
export const selectQuestionIndex = (state: RootState) => selectExamRuntime(state).questionIndex;
export const selectReviewIndex = (state: RootState) => selectExamRuntime(state).reviewIndex;

export const _selectExamQuestion = (state: RootState) =>
    selectSection(state).questions[state.examRuntime.questionIndex];

export const selectFromURL = (state: RootState) => selectExamRuntime(state).fromURL;

export const selectExamCreateDialog = (state: RootState) => selectExamRuntime(state).createDialog;
