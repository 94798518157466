import { Source } from "./craft";

export class QuestionCollect {
    collected: boolean = false;
    word: WordCollect = new WordCollect();
    source: Source = new Source();
}

export class WordCollect {
    kind: number = 0;
    word_id: string = "";
    pair_id: string = "";
    source_qid: string = "";
}
