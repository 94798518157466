import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useSelector } from "react-redux";
import { SwitchContent } from "../../../../bblib/component/SwitchContent";
import { sleep } from "../../../../bblib/tools/time";
import { Pay } from "../../../../component/dialog/PayDialog";
import { BuyFlashCoinKind, BuyFlashCoinMap } from "../../../../model/permission/flash";
import { Trade } from "../../../../model/security/Pay";
import { TradeKind } from "../../../../model/security/TradeKind";
import { User } from "../../../../model/UserData";
import { actionSetPayFlashDialog } from "../../../../store/dialog/reducer";
import { selectPayFlashDialog } from "../../../../store/dialog/selector";
import { useAppDispatch } from "../../../../store/hooks";
import { actionSetUserData } from "../../../../store/user/reducer";
import { Colors } from "../../../../tools/color";

export function BuyCoinDialog() {
    const dispatch = useAppDispatch();

    const open = useSelector(selectPayFlashDialog);
    const setOpen = (b: boolean) => {
        dispatch(actionSetPayFlashDialog(b));
    };

    const handleOnClose = () => {
        setOpen(false);
    };
    const kinds = [
        BuyFlashCoinKind.KindCoin50,
        BuyFlashCoinKind.KindCoin100,
        BuyFlashCoinKind.KindCoin200,
    ];
    return (
        <Dialog open={open} onClose={handleOnClose} fullWidth maxWidth="md">
            <DialogTitle>扫码购买闪卡额度</DialogTitle>

            <DialogContent>
                <SwitchContent
                    titles={kinds.map((k) => BuyFlashCoinMap(k))}
                    contents={kinds.map((k, index) => (
                        <BuyCoinPay key={index} buyKind={k} setOpen={setOpen} />
                    ))}
                    contentMargin="0 0 0 0"
                    defaultIndex={1}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOnClose}>取消</Button>
            </DialogActions>
        </Dialog>
    );
}

function BuyCoinPay(props: { buyKind: BuyFlashCoinKind; setOpen: (b: boolean) => void }) {
    const { buyKind, setOpen } = props;
    const dispatch = useAppDispatch();
    return (
        <Pay
            kind={TradeKind.FlashCoin}
            param={buyKind.toString()}
            successMsg="支付成功，即将关闭窗口"
            onSuccess={(t: Trade, user: User) => {
                dispatch(actionSetUserData(user));
                sleep(1000).then(() => {
                    setOpen(false);
                });
            }}
            hintDiv={
                <Box
                    sx={{
                        mt: "10px",
                        color: Colors["gray"],
                        mb: "20px",
                        fontSize: 13,
                        width: "100%",
                        textAlign: "right",
                    }}
                >
                    购买成功的闪卡额度不退不换
                </Box>
            }
        />
    );
}
