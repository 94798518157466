export class Comment {
    id: string = "";
    kind: number = 0;
    user_id: string = "";
    nick_name: string = "";
    content: CommentContent = new CommentContent();
    like_count: number = 0;
    replies: Comment[] = [];
    reply_total_count: number = 0;
    reply_to_name: string = "";
    liked: boolean = false;
    anonymous: boolean = false;
    review_status: number = 0;
}

export enum ReviewStatus {
    Uploading = 0,
    Reviewed = 1,
    Rejected = 2,
}

export enum CommentKind {
    Talk = 1,
    Question = 2,
    Answer = 3,
}

export class CommentContent {
    text: string = "";
    update_time_str: string = "";
}
